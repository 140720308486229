.video-wrapper {
  padding: 10px;
  border: 3px solid rgba(220, 220, 220, 1);
  border-radius: 5px;
}

video {
  background-color: #000;
}

.video-full-size {
  width: 640px;
  height: 480px;
}
