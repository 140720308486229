.player {
  width: 640px;
  height: 720px;
  position: relative;
  margin-inline: auto;
  justify-content: center;
}

.player.view-zoom {
  width: 640px;
  height: 360px;
  position: relative;
  margin-inline: auto;
  justify-content: center;
}

.player.view-zoom-second-camera {
  width: 640px;
  height: 240px;
  position: relative;
  margin-inline: auto;
  justify-content: center;
}

.player-controls {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  z-index: 500;
}

.videoContainer {
  background: rgba(0, 0, 0, 0.5);
  width: 640px;
  height: 720px;
  overflow-y: hidden;
  text-align: center;
  z-index: 200;
  margin-inline: auto;
  justify-content: center;
}

.videoContainer.view-zoom {
  background: rgba(0, 0, 0, 0.5);
  width: 640px;
  height: 360px;
  overflow-y: hidden;
  text-align: center;
  z-index: 200;
  margin-inline: auto;
  justify-content: center;
}

.videoContainer.view-zoom-second-camera {
  background: rgba(0, 0, 0, 0.5);
  width: 640px;
  height: 240px;
  overflow-y: hidden;
  text-align: center;
  z-index: 200;
  margin-inline: auto;
  justify-content: center;
}

.videoContainer.view-fullscreen {
  background: rgba(0, 0, 0, 0.5);
  width: 1920px;
  height: 1080px;
  overflow-y: hidden;
  text-align: center;
  z-index: 200;
  margin-inline: auto;
  justify-content: center;
}

.proctorCombinedVideo {
  width: 640px;
  height: 720px;
}

.proctorCombinedVideo.view-fullscreen {
  width: 1920px;
  height: 2160px;
}
.proctorCombinedVideo.view-fullscreen-combined {
  width: 100%;
  height: 1080px;
}

.proctorCombinedWithSecondCameraVideo {
  width: 640px;
  height: 720px;
}

.proctorCombinedWithSecondCameraVideo.view-fullscreen {
  width: 1920px;
  height: 3240px;
}
.proctorCombinedWithSecondCameraVideo.view-fullscreen-combined {
  width: 100%;
  height: 1080px;
}
